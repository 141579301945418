<template>
  <div class="enterprise-positioning-container">
    <div class="enterprise-positioning-container-content">
      <div class="content-main">
        <div class="content-main-troduce">专业从事特色原料药及制剂的开发和持证平台</div>
        <div class="content-main-core">
          <div class="content-main-core-row">
            <div class="content-main-core-cn">企业</div>
            <div class="content-main-core-en">ENTERPRISE</div>
          </div>
          <div class="content-main-core-row">
            <div class="content-main-core-cn">定位</div>
            <div class="content-main-core-en">POSITIONING</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'enterprise-positioning'
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>