<template>
  <div id="focus-footer" class="focus-footer">
    <div class="focus-footer-wrap">
      <div class="footer flex-center">
        <div style="width: 100%">
          <p class="item">
            <img
              src="@/assets/images/logo-footer.png"
              height="39"
              width="113"
              alt="logo"
            />
            <span class="phone">0731 - 86983908</span>
          </p>
          <p class="splitLine" />
          <p class="item">
            <span class="copyright"
              >Copyright © 2023 湖南柏齐鹤药物研发有限公司 
              <a class="copyright" href=" https://beian.miit.gov.cn" target="_blank">苏ICP备2022006676号-1</a>
            </span>
          </p>
        </div>
      </div>
      <div class="qrCode">
        <!-- <img
          src="@/assets/images/wxfks.jpg"
          height="80"
          width="80"
          alt="公众号二维码"
        />
        <p class="tips">扫一扫关注公众号</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-info'
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>