<template>
  <div>
    <div class="my-home-container">
      <div class="my-home-container-content">
        <div class="my-home-text">
          <div class="vertical-line"></div>
          <div class="my-home-text-troduce">
            <div>
              <span>向社会提供优质的药品和高效的</span>
              <i>健康服务</i>
            </div>
            <!-- <div class="my-home-text-detail">了解详情</div> -->
          </div>
        </div>
        <div class="my-home-quality">
          <div
            v-for="(item, index) in qualityList"
            :key="item.cnText"
            class="my-home-quality-item"
            @mouseover="changeBg(index)"
          >
            <img
              :src="require(`@/assets/images/${item.imgTitle}`)"
              alt=""
              class="item-quality-logo"
            />
            <div class="quality-item-text quality-item-cn">
              {{ item.cnText }}
            </div>
            <div class="quality-item-text quality-item-en">
              {{ item.enText }}
            </div>
            <transition name="quality">
              <img
                v-show="item.isShow"
                src="@/assets/images/quality.png"
                class="item-quality-bg"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="about-us-container">
      <div class="about-us-container-content">
        <div class="about-us-troduce">
          <div class="about-us-troduce-title">关于我们</div>
          <div class="underline">
            <div class="underline-first"></div>
            <div class="underline-second"></div>
          </div>
          <div class="about-us-troduce-content">
            湖南柏齐鹤药物研发有限公司，成立于2021年。总部位于长沙，研发中心位于南京。
            公司始终秉承“专业、专注、诚信、共赢、平台”的文化理念，致力于向社会提供优质
            的药品和高效的健康服务。
          </div>

          <img src="@/assets/images/about-logo.png" class="about-logo" />
        </div>
      </div>
    </div>
    <div class="enterprise-positioning-container">
      <div class="enterprise-positioning-container-content">
        <div class="content-main">
          <div class="content-main-troduce">
            专业从事特色原料药及制剂的开发和持证平台
          </div>
          <div class="content-main-core">
            <div class="content-main-core-row">
              <div class="content-main-core-cn">企业</div>
              <div class="content-main-core-en">ENTERPRISE</div>
            </div>
            <div class="content-main-core-row">
              <div class="content-main-core-cn">定位</div>
              <div class="content-main-core-en">POSITIONING</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'my-home',
  data() {
    return {
      qualityList: [
        {
          cnText: '专业',
          enText: 'PROFESSIONAL',
          imgTitle: 'zhuanye.png',
          isShow: true
        },
        {
          cnText: '专注',
          enText: 'ABSORBED',
          imgTitle: 'zhuanzhu.png',
          isShow: false
        },
        {
          cnText: '诚信',
          enText: 'SINCERITY',
          imgTitle: 'chengxin.png',
          isShow: false
        },
        {
          cnText: '共赢',
          enText: 'WIN-WIN',
          imgTitle: 'gongying.png',
          isShow: false
        },
        {
          cnText: '平台',
          enText: 'PLATFORM',
          imgTitle: 'pingtai.png',
          isShow: false
        }
      ]
    };
  },
  methods: {
    changeBg(key) {
      this.qualityList.forEach((item, index) => {
        if (index === key) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>