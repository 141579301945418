import Vue from 'vue'
import VueRouter from 'vue-router'

import MyHome from "@/views/my-home";
import AboutUs from "@/views/about-us";
import EnterprisePositioning from "@/views/enterprise-positioning";
import ProductsServices from "@/views/products-services";
import CorporateCulture from "@/views/corporate-culture";
import JoinUs from "@/views/join-us";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'my-home'
  },
  {
    // 首页
    path: '/my-home',
    name: 'my-home',
    component: MyHome,
  },
  {
    // 关于我们
    path: '/about-us',
    name: 'about-us',
    component: AboutUs,
  },
  {
    // 企业定位
    path: '/enterprise-positioning',
    name: 'enterprise-positioning',
    component: EnterprisePositioning,
  },
  {
    // 产品与服务
    path: '/products-services',
    name: 'products-services',
    component: ProductsServices,
  },
  {
    // 企业文化
    path: '/corporate-culture',
    name: 'corporate-culture',
    component: CorporateCulture,
  },
  {
    // 加入我们
    path: '/join-us',
    name: 'join-us',
    component: JoinUs,
  },
]



const createRouter = () => new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const router = createRouter()

export default router
