<template>
  <div class="join-us-container">
    <div class="join-us-container-content">
      <div class="title-en">JOIN US</div>
      <div class="title-cn">加入我们</div>
      <div class="area-tab">
        <div
          v-for="(item, index) in recruitList"
          :key="item.areaId"
          class="area-item"
        >
          <div class="area-item-image" @click="areaIndex = index">
            <img
              v-if="index !== areaIndex"
              src="@/assets/images/gray-spot.png"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/choose-spot.png"
              alt=""
              class="choose-spot"
            />
          </div>
          <div class="area-item-text" :class="areaActiveTextComputed(index)">
            {{ item.areaName }}
          </div>
        </div>
      </div>

      <div class="job-list">
        <div
          v-for="jobItem in recruitList[areaIndex].jobList.slice(
            0,
            recruitList[areaIndex].maximum
          )"
          :key="jobItem.id"
          class="job-item"
        >
          <div class="job-item-title">
            <img src="@/assets/images/favicon.png" alt="" />
            <div>{{ jobItem.jobName }}</div>
          </div>
          <div class="job-item-outline">
            <div class="">工作地点： {{ jobItem.workPlace }}</div>
            <div class="division-gray"></div>
            <div>招聘人数： {{ jobItem.number }}</div>
            <div class="division-gray"></div>
            <div>发布时间： {{ jobItem.releaseTime }}</div>
          </div>

          <div class="job-item-detail" v-if="jobItem.isOpen">
            <div class="job-item-detail-title">岗位职责:</div>
            <div class="job-item-detail-text" v-html="jobItem.JobDuty"></div>
            <div class="job-item-detail-title">任职要求:</div>
            <div
              class="job-item-detail-text"
              v-html="jobItem.JobRequirements"
            ></div>
            <div class="job-item-detail-title">待遇区间:</div>
            <div class="job-item-detail-text" v-html="jobItem.salary"></div>
          </div>

          <div v-if="jobItem.isOpen" class="job-item-common job-item-open">
            <div class="job-item-detail job-item-open-detail">收起详情</div>
            <img
              src="@/assets/images/more.svg"
              @click="jobItem.isOpen = false"
            />
          </div>
          <div v-else class="job-item-common job-item-close">
            <div class="job-item-detail job-item-close-detail">查看详情</div>
            <img
              src="@/assets/images/more.svg"
              @click="jobItem.isOpen = true"
            />
          </div>
        </div>
      </div>
      <div
        class="job-list-more"
        v-if="
          recruitList[areaIndex].jobList.length > this.initNum
        "
      >
        <img
          src="@/assets/images/down-arrow.png"
          class="job-list-more-image"
          @click="recruitListExpansion"
          :style="recruitListExpansionComputed"
        />
      </div>
    </div>

    <div class="information">
      <div class="tips-wrap">
        <div class="tips-left">
          <div class="information-divider">
            <div class="information-divider-left"></div>
            <div class="information-divider-right"></div>
          </div>

          <el-row class="prompt" type="flex">
            <el-col :span="2">
              <img src="@/assets/images/email.png" height="60" width="80"
            /></el-col>

            <el-col :span="10">
              <div class="hrContactInformation-wrap">
                <div class="hrContactInformation-item">
                  <span class="hrTitle hrTelTitle">HR电话: </span>
                  <span class="hrtext">hr01_focus@163.com</span>
                </div>
                <div class="hrContactInformation-item">
                  <span class="hrTitle hrEmailTitle">HR邮箱: </span>
                  <span class="hrtext">15366167588(刘女士）</span>
                </div>
              </div>
            </el-col>

            <el-col :span="14" class="information-deliver">
              除了本网站外，还可以通过公司在<i style="color: #22609e"
                >BOSS直聘</i
              >和<i style="color: #36b4c3">前程无忧</i
              >等发布的招聘职位投递简历。
              我们将进行简历筛选，如满足相关职位要求，则在一周内致电，尽快联系您。
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <img src="@/assets/images/bg-join-us.png" class="bg-join-us" />
  </div>
</template>

<script>
export default {
  name: 'join-us',
  data() {
    return {
      areaIndex: 0,
      initNum:3,  // 初始显示数据条数
      recruitList: [
        {
          areaId: 0,
          areaName: '全部',
          maximum: 3, // 初始最大显示条数
          isExpansion: false,
          jobList: [
            {
              id: 0,
              jobName: '质量负责人',
              workPlace: '长沙',
              number: 1,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、负责质量管理部的全面工作；<br />' +
                '2、保证研发中心质量管理体系符合GLP注册、认证要求；<br />' +
                '3、督促委托生产企业按照GMP要求生产；<br />' +
                '4、负责产品上市放行；<br />' +
                '5、负责对接药监系统，接受各类审计，负责审计物料供应商。<br />',
              JobRequirements:
                '1、药学相关专业，本科及以上学历（或中级专业技术职称或职业药师资格）；<br />' +
                '2、有5年以上化药研发企业或生产企业的质量管理经验；<br />' +
                '3、精通GMP、GLP质量管理体系和ICH规范；<br />' +
                '4、大型制药企业质量管理经验和QC工作经验优先。<br />',
              salary: '15-16k',
              isOpen: false
            },
            {
              id: 1,
              jobName: '文件QA',
              workPlace: '长沙',
              number: 1,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、质量管理体系的日常维护工作。<br />' +
                '2、质量管理体系文件变更、新增、分发、收回、销毁，人员培训档案归档。<br />' +
                '3、供应商资料的收集、审核、归档。<br />' +
                '4、受托生产企业批生产记录和检验记录收集、归档。<br />' +
                '5、委托生产过程中偏差、变更、纠正预防措施实施情况处理跟踪工作。<br />' +
                '6、产品稳定性考察、原料评价、中间产品及成品的质量稳定性文件档案管理。<br />' +
                '7、验证方案、验证报告、产品年度质量回顾、药品年度报告、用户访问、档案管理等工作。<br />' +
                '8、协助生产过程中变更、OOS +/OOT调查。<br />' +
                '9、协助处理质量事故、产品召回等工作。<br />',
              JobRequirements:
                '1、熟悉原辅料供应商管理。<br />' +
                '2、熟悉药品GMP、药品上市后变更管理办法、药品生产监督管理办法、熟悉产品生产过程控制点监督工作。<br />' +
                '3、熟悉质量体系文件管理。<br />',
              salary: '4-7k',
              isOpen: false
            },
            {
              id: 2,
              jobName: '合成项目经理',
              workPlace: '南京',
              number: 2,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、负责合成路线的设计和合成方案的制定；<br />' +
                '2、推进项目的具体实施、进度安排及申报资料的撰写；<br />' +
                '3、负责实验组的卫生及安全管理；<br />' +
                '4、CTD申报资料的撰写。<br />',
              JobRequirements:
                '1、本科以上学历，有机化学、药物化学等相关专业；<br />' +
                '2、在国内外大型制药企业从事合成研究3年以上。<br />',
              salary: '10-15k',
              isOpen: false
            },
            {
              id: 3,
              jobName: '药物合成实验员',
              workPlace: '南京',
              number: 6,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、根据组长的安排完成实验及记录的填写；<br />' +
                '2、负责相关仪器的维护和使用；<br />' +
                '3、上级领导交代的其他工作。<br />',
              JobRequirements:
                '1、工作两年以上本科学历，药学、化学或相关专业；<br />' +
                '2、大学期间帮助老师完成课题，熟练使用合成设备优先考虑。<br />',
              salary: '9-12K',
              isOpen: false
            },
            {
              id: 4,
              jobName: '合成分析实验员',
              workPlace: '南京',
              number: 6,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、熟练使用HPLC等分析仪器，管理维护和故障排除；<br />' +
                '2、按时完成各项检验任务；<br />' +
                '3、上级主管安排其他的实验事务。<br />',
              JobRequirements:
                '1、药物分析、分析化学等相关专业，本科学历；<br />' +
                '2、2年以上药物分析相关工作经验；<br />' +
                '3、熟练使用液相及相关方法开发实验流程；<br />' +
                '4、职业道德良好，善于沟通，团结敬业；<br />' +
                '5、欢迎优秀应届毕业生。<br />',
              salary: '6-9K',
              isOpen: false
            }
          ]
        },
        {
          areaId: 1,
          areaName: '长沙',
          maximum: 3, // 最大显示条数
          isExpansion: false,
          jobList: [
            {
              id: 0,
              jobName: '质量负责人',
              workPlace: '长沙',
              number: 1,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、负责质量管理部的全面工作；<br />' +
                '2、保证研发中心质量管理体系符合GLP注册、认证要求；<br />' +
                '3、督促委托生产企业按照GMP要求生产；<br />' +
                '4、负责产品上市放行；<br />' +
                '5、负责对接药监系统，接受各类审计，负责审计物料供应商。<br />',
              JobRequirements:
                '1、药学相关专业，本科及以上学历（或中级专业技术职称或职业药师资格）；<br />' +
                '2、有5年以上化药研发企业或生产企业的质量管理经验；<br />' +
                '3、精通GMP、GLP质量管理体系和ICH规范；<br />' +
                '4、大型制药企业质量管理经验和QC工作经验优先。<br />',
              salary: '15-16k',
              isOpen: false
            },
            {
              id: 1,
              jobName: '文件QA',
              workPlace: '长沙',
              number: 1,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、质量管理体系的日常维护工作。<br />' +
                '2、质量管理体系文件变更、新增、分发、收回、销毁，人员培训档案归档。<br />' +
                '3、供应商资料的收集、审核、归档。<br />' +
                '4、受托生产企业批生产记录和检验记录收集、归档。<br />' +
                '5、委托生产过程中偏差、变更、纠正预防措施实施情况处理跟踪工作。<br />' +
                '6、产品稳定性考察、原料评价、中间产品及成品的质量稳定性文件档案管理。<br />' +
                '7、验证方案、验证报告、产品年度质量回顾、药品年度报告、用户访问、档案管理等工作。<br />' +
                '8、协助生产过程中变更、OOS +/OOT调查。<br />' +
                '9、协助处理质量事故、产品召回等工作。<br />',
              JobRequirements:
                '1、熟悉原辅料供应商管理。<br />' +
                '2、熟悉药品GMP、药品上市后变更管理办法、药品生产监督管理办法、熟悉产品生产过程控制点监督工作。<br />' +
                '3、熟悉质量体系文件管理。<br />',
              salary: '4-7k',
              isOpen: false
            }
          ]
        },
        {
          areaId: 2,
          areaName: '南京',
          maximum: 3, // 最大显示条数
          isExpansion: false,
          jobList: [
            {
              id: 2,
              jobName: '合成项目经理',
              workPlace: '南京',
              number: 2,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、负责合成路线的设计和合成方案的制定；<br />' +
                '2、推进项目的具体实施、进度安排及申报资料的撰写；<br />' +
                '3、负责实验组的卫生及安全管理；<br />' +
                '4、CTD申报资料的撰写。<br />',
              JobRequirements:
                '1、本科以上学历，有机化学、药物化学等相关专业；<br />' +
                '2、在国内外大型制药企业从事合成研究3年以上。<br />',
              salary: '10-15k',
              isOpen: false
            },
            {
              id: 3,
              jobName: '药物合成实验员',
              workPlace: '南京',
              number: 6,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、根据组长的安排完成实验及记录的填写；<br />' +
                '2、负责相关仪器的维护和使用；<br />' +
                '3、上级领导交代的其他工作。<br />',
              JobRequirements:
                '1、工作两年以上本科学历，药学、化学或相关专业；<br />' +
                '2、大学期间帮助老师完成课题，熟练使用合成设备优先考虑。<br />',
              salary: '9-12K',
              isOpen: false
            },
            {
              id: 4,
              jobName: '合成分析实验员',
              workPlace: '南京',
              number: 6,
              releaseTime: '2023-04-13',
              JobDuty:
                '1、熟练使用HPLC等分析仪器，管理维护和故障排除；<br />' +
                '2、按时完成各项检验任务；<br />' +
                '3、上级主管安排其他的实验事务。<br />',
              JobRequirements:
                '1、药物分析、分析化学等相关专业，本科学历；<br />' +
                '2、2年以上药物分析相关工作经验；<br />' +
                '3、熟练使用液相及相关方法开发实验流程；<br />' +
                '4、职业道德良好，善于沟通，团结敬业；<br />' +
                '5、欢迎优秀应届毕业生。<br />',
              salary: '6-9K',
              isOpen: false
            }
          ]
        }
      ]
    };
  },
  computed: {
    areaActiveTextComputed() {
      return (index) => {
        return {
          'area-item-text-common': index !== this.areaIndex,
          'area-item-text-actived': index === this.areaIndex
        };
      };
    },
    recruitListExpansionComputed(){
      if(this.recruitList[this.areaIndex].isExpansion){
        return {
          transform: 'rotate(180deg)'
        }
      }else{
        return {
          transform: 'rotate(0deg)'
        }
      }
    }
  },
  methods:{
    recruitListExpansion(){
      console.log('object');
      this.recruitList[this.areaIndex].isExpansion = !this.recruitList[this.areaIndex].isExpansion
      if(this.recruitList[this.areaIndex].isExpansion){
        this.recruitList[this.areaIndex].maximum = this.recruitList[this.areaIndex].length
      }else{
        console.log(this.recruitList.initNum,'this.recruitList.initNum');
        this.recruitList[this.areaIndex].maximum = this.initNum
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>