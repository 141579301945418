<template>
  <div id="app">
    <HeaderMenu />
    <router-view />
    <FooterInfo />
  </div>
</template>

<script>
import HeaderMenu from '@/components/header-menu';
import FooterInfo from '@/components/footer-info';

export default {
  name: 'App',
  components: {
    HeaderMenu,
    FooterInfo
  }
};
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
</style>
