<template>
  <div class="products-services-container">
    <div class="products-services-container-content">
      <div class="title-en">Products and Services</div>
      <div class="title-cn">产品与服务</div>

      <div class="carousel">
        <el-carousel height="520px" indicator-position="none" arrow="never">
          <el-carousel-item v-for="item in 1" :key="item">
            <div class="carousel-item-content">
              <div class="carousel-item-title">
                <div class="carousel-item-image">
                  <img src="@/assets/images/drug-name.svg" width="50" />
                </div>
                <span class="carousel-item-title-text">注射用头孢唑肟钠</span>
              </div>
              <div class="carousel-item-detail">
                公司在研产品：注射用头孢唑肟钠（国家药监局已受理）
              </div>
              <div class="carousel-item-remarks">【按化学药新四类申报】</div>
              <p class="splitLine" />
              <div class="carousel-item-contact">
                <img
                  src="@/assets/images/telephone.png"
                  style="margin-right: 17px"
                  align="left"
                />
                <div class="carousel-item-contact-title">全国热线电话</div>
                <div class="carousel-item-contact-tel">0731-86983908</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'products-services',
  components: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>