<template>
  <div class="corporate-culture-container">
    <div class="corporate-culture-container-content">
      <div class="title-en">CORPORATE CULTURE</div>
      <div class="title-cn">企业文化</div>
      <div class="building">
        <div class="building-item building-item-one">
          <img src="@/assets/images/button.png" alt="" width="20" />
          <div class="building-item-text">进取</div>
        </div>
        <div class="building-item building-item-two">
          <img src="@/assets/images/button.png" alt="" width="20" />
          <div class="building-item-text">合作</div>
        </div>
        <div class="building-item building-item-three">
          <img src="@/assets/images/button.png" alt="" width="20" />
          <div class="building-item-text">团结</div>
        </div>
        <div class="building-item building-item-four">
          <img src="@/assets/images/button.png" alt="" width="20" />
          <div class="building-item-text">责任</div>
        </div>
        <div class="building-item building-item-five">
          <img src="@/assets/images/button.png" alt="" width="20" />
          <div class="building-item-text">担当</div>
        </div>
      </div>
      <div class="values-title">
        <div>核心价值观</div>
        <div class="underline">
          <div class="underline-first"></div>
          <div class="underline-second"></div>
        </div>
      </div>
    </div>
    <div class="values">
      <div class="values-content">
        <div v-for="item in valuesList" :key="item.name" class="values-item">
          <img :src="require(`@/assets/images/${item.imgName}`)" alt="" />
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="culture-quality"></div>
  </div>
</template>

<script>
export default {
  name: 'corporate-culture',
  data() {
    return {
      valuesList: [
        {
          name: '专业专注',
          imgName: 'arrow.png'
        },
        {
          name: '追求卓越',
          imgName: 'zhuiqiu.png'
        },
        {
          name: '创造价值',
          imgName: 'value.png'
        },
        {
          name: '合作共赢',
          imgName: 'win.png'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>