var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"join-us-container"},[_c('div',{staticClass:"join-us-container-content"},[_c('div',{staticClass:"title-en"},[_vm._v("JOIN US")]),_c('div',{staticClass:"title-cn"},[_vm._v("加入我们")]),_c('div',{staticClass:"area-tab"},_vm._l((_vm.recruitList),function(item,index){return _c('div',{key:item.areaId,staticClass:"area-item"},[_c('div',{staticClass:"area-item-image",on:{"click":function($event){_vm.areaIndex = index}}},[(index !== _vm.areaIndex)?_c('img',{attrs:{"src":require("@/assets/images/gray-spot.png"),"alt":""}}):_c('img',{staticClass:"choose-spot",attrs:{"src":require("@/assets/images/choose-spot.png"),"alt":""}})]),_c('div',{staticClass:"area-item-text",class:_vm.areaActiveTextComputed(index)},[_vm._v(" "+_vm._s(item.areaName)+" ")])])}),0),_c('div',{staticClass:"job-list"},_vm._l((_vm.recruitList[_vm.areaIndex].jobList.slice(
          0,
          _vm.recruitList[_vm.areaIndex].maximum
        )),function(jobItem){return _c('div',{key:jobItem.id,staticClass:"job-item"},[_c('div',{staticClass:"job-item-title"},[_c('img',{attrs:{"src":require("@/assets/images/favicon.png"),"alt":""}}),_c('div',[_vm._v(_vm._s(jobItem.jobName))])]),_c('div',{staticClass:"job-item-outline"},[_c('div',{},[_vm._v("工作地点： "+_vm._s(jobItem.workPlace))]),_c('div',{staticClass:"division-gray"}),_c('div',[_vm._v("招聘人数： "+_vm._s(jobItem.number))]),_c('div',{staticClass:"division-gray"}),_c('div',[_vm._v("发布时间： "+_vm._s(jobItem.releaseTime))])]),(jobItem.isOpen)?_c('div',{staticClass:"job-item-detail"},[_c('div',{staticClass:"job-item-detail-title"},[_vm._v("岗位职责:")]),_c('div',{staticClass:"job-item-detail-text",domProps:{"innerHTML":_vm._s(jobItem.JobDuty)}}),_c('div',{staticClass:"job-item-detail-title"},[_vm._v("任职要求:")]),_c('div',{staticClass:"job-item-detail-text",domProps:{"innerHTML":_vm._s(jobItem.JobRequirements)}}),_c('div',{staticClass:"job-item-detail-title"},[_vm._v("待遇区间:")]),_c('div',{staticClass:"job-item-detail-text",domProps:{"innerHTML":_vm._s(jobItem.salary)}})]):_vm._e(),(jobItem.isOpen)?_c('div',{staticClass:"job-item-common job-item-open"},[_c('div',{staticClass:"job-item-detail job-item-open-detail"},[_vm._v("收起详情")]),_c('img',{attrs:{"src":require("@/assets/images/more.svg")},on:{"click":function($event){jobItem.isOpen = false}}})]):_c('div',{staticClass:"job-item-common job-item-close"},[_c('div',{staticClass:"job-item-detail job-item-close-detail"},[_vm._v("查看详情")]),_c('img',{attrs:{"src":require("@/assets/images/more.svg")},on:{"click":function($event){jobItem.isOpen = true}}})])])}),0),(
        _vm.recruitList[_vm.areaIndex].jobList.length > this.initNum
      )?_c('div',{staticClass:"job-list-more"},[_c('img',{staticClass:"job-list-more-image",style:(_vm.recruitListExpansionComputed),attrs:{"src":require("@/assets/images/down-arrow.png")},on:{"click":_vm.recruitListExpansion}})]):_vm._e()]),_c('div',{staticClass:"information"},[_c('div',{staticClass:"tips-wrap"},[_c('div',{staticClass:"tips-left"},[_vm._m(0),_c('el-row',{staticClass:"prompt",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":2}},[_c('img',{attrs:{"src":require("@/assets/images/email.png"),"height":"60","width":"80"}})]),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"hrContactInformation-wrap"},[_c('div',{staticClass:"hrContactInformation-item"},[_c('span',{staticClass:"hrTitle hrTelTitle"},[_vm._v("HR电话: ")]),_c('span',{staticClass:"hrtext"},[_vm._v("hr01_focus@163.com")])]),_c('div',{staticClass:"hrContactInformation-item"},[_c('span',{staticClass:"hrTitle hrEmailTitle"},[_vm._v("HR邮箱: ")]),_c('span',{staticClass:"hrtext"},[_vm._v("15366167588(刘女士）")])])])]),_c('el-col',{staticClass:"information-deliver",attrs:{"span":14}},[_vm._v(" 除了本网站外，还可以通过公司在"),_c('i',{staticStyle:{"color":"#22609e"}},[_vm._v("BOSS直聘")]),_vm._v("和"),_c('i',{staticStyle:{"color":"#36b4c3"}},[_vm._v("前程无忧")]),_vm._v("等发布的招聘职位投递简历。 我们将进行简历筛选，如满足相关职位要求，则在一周内致电，尽快联系您。 ")])],1)],1)])]),_c('img',{staticClass:"bg-join-us",attrs:{"src":require("@/assets/images/bg-join-us.png")}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information-divider"},[_c('div',{staticClass:"information-divider-left"}),_c('div',{staticClass:"information-divider-right"})])
}]

export { render, staticRenderFns }