import Vue from 'vue'
import App from './App.vue'

import router from './router'

import '@/assets/styles/global-clear.scss'// 全局的默认样式的清空


import { Carousel,CarouselItem,Divider,Row,Col } from 'element-ui';

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Divider)
Vue.use(Row)
Vue.use(Col)


// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
