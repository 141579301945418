<template>
  <div class="about-us-container">
    <div class="about-us-container-content">
      <div class="about-us-troduce">
        <div class="about-us-troduce-title">关于我们</div>
        <div class="underline">
          <div class="underline-first"></div>
          <div class="underline-second"></div>
        </div>
        <div class="about-us-troduce-content">
          湖南柏齐鹤药物研发有限公司，成立于2021年。总部位于长沙，研发中心位于南京。
          公司始终秉承“专业、专注、诚信、共赢、平台”的文化理念，致力于向社会提供优质
          的药品和高效的健康服务。
        </div>

        <img src="@/assets/images/about-logo.png" class="about-logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-us'
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>